import { createTheme, Theme } from '@mui/material/styles';

const theme: Theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: 'ghostwhite',
      paper: 'ghostwhite',
    },
    primary: {
      main: '#c00',
    },
    secondary: {
      main: 'rgb(248, 248, 255)',
    },
  },
  typography: {
    h1: {
      fontSize: '2.2rem',
      fontFamily: ['Roboto Slab', 'serif'].join(','),
      lineHeight: '50px',
      textShadow: '1px 1px 3px lightgrey',
    }
  }
});

export default theme;

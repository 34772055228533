import React from 'react';
import {
  Typography,
  Grid,
  Box,
  SxProps,
} from '@mui/material';
import Logo from './Logo';
import { useTheme, Theme } from '@mui/material/styles';

const Header: React.FC = () => {
  const theme: Theme = useTheme();
  const styles: Record<string, SxProps> = {
    root: {
      marginTop: 0,
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      borderBottom: '1px solid lightgrey',
      boxSizing: 'border-box',
    },
    titleText: {
      display: 'inline-block',
      height: '50px',
      maxHeight: '52px',
      marginLeft: '5px',
      '& .MuiTypography-h1': {
        paddingRight: theme.spacing(2),
        color: theme.palette.primary.main,
        verticalAlign: 'middle',
        display: 'inline',
      },
    },
    the: {
      display: 'block',
      fontSize: '50%',
      lineHeight: '50%',
      marginBottom: '-2px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '-4px',
      },
    },
    tagline: {
      fontFamily: 'Roboto Slab',
      color: '#464646',
    },
  };

  return (
    <Grid container spacing={3} sx={styles.root}>
      <Grid item xs={12}>
        <Logo />
        <Box sx={styles.titleText}>
          <Typography variant="h1">
            <Box component={'span'} sx={styles.the}>The</Box>
            Fresno News
          </Typography>
        </Box>
        <Typography variant="subtitle1" sx={styles.tagline}>
          The latest news stories from around the valley.
        </Typography>
      </Grid>
    </Grid>
  )
    ;
};

export default Header;

import { FeedList } from '../types/FeedList';
import { Cities } from '../types';

// url: 'https://news.google.com/rss/search?q=fresno,ca&hl=en-US&gl=US&ceid=US%3Aen',

const feedList: FeedList[] = [
  { city: Cities.FRESNO },
  { city: Cities.CLOVIS },
  { city: Cities.SANGER },
  { city: Cities.SELMA },
  { city: Cities.KINGSBURG },
  { city: Cities.REEDLEY },
  { city: Cities.KERMAN },
  { city: Cities.COALINGA },
  { city: Cities.MENDOTA },
];

export default feedList;

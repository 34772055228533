import React from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import App from './pages/App';
import theme from './Theme';
import { createRoot } from 'react-dom/client';

const rootNode = document.getElementById('root');

if (!rootNode) {
  throw new Error('Could not find root element');
}

createRoot(rootNode).render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);


import React from 'react';
import {
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Logo: React.FC = () => {
  const theme: Theme = useTheme();
  const styles: Record<string, SxProps> = {
    root: {
      height: '50px',
      maxHeight: '50px',
      width: '120px',
      boxSizing: 'border-box',
      fontFamily: 'Roboto Slab',
      display: 'inline-block',
      [theme.breakpoints.down('xs')]: {
        height: '45px',
        maxHeight: '45px',
      },
    },
    letterBox: {
      display: 'inline-block',
      width: '35px',
      maxWidth: '35px',
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      fontSize: '36px',
      fontWeight: 800,
      lineHeight: '50px',
      textAlign: 'center',
      marginRight: theme.spacing(.5),
      borderRight: '1px solid #e00',
      borderBottom: '1px solid #e00',
      borderLeft: '1px solid #600',
      borderTop: '1px solid #600',
      textShadow: '-1px -1px 1px #300',
      [theme.breakpoints.down('xs')]: {
        fontSize: '34px',
        lineHeight: '45px',
      },
    }
  }
  return (
    <Box sx={styles.root}>
      <Box sx={styles.letterBox}>
        T
      </Box>
      <Box sx={styles.letterBox}>
        F
      </Box>
      <Box sx={styles.letterBox}>
        N
      </Box>
    </Box>
  );
};

export default Logo;

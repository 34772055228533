export enum Cities {
  FRESNO = 'fresno',
  CLOVIS = 'clovis',
  SANGER = 'sanger',
  SELMA = 'selma',
  KINGSBURG = 'kingsburg',
  REEDLEY = 'reedley',
  KERMAN = 'kerman',
  COALINGA = 'coalinga',
  MENDOTA = 'mendota',
}

import React, {
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  Typography,
  AppBar,
  Box,
  SxProps,
} from '@mui/material';
import {
  SectionParams,
  StoryModel,
} from '../types';
import Story from './Story';
import getCityStories from '../data/getCityStories';
import { useTheme } from '@mui/material/styles';

const Section: React.FC<SectionParams> = ({ city }) => {
  const theme= useTheme();
  const [storyList, setStoryList] = useState<StoryModel[]>([]);

  const styles: Record<string, SxProps> = {
    root: {
      marginBottom: theme.spacing(4),
      '& .MuiAppBar-root': {
        padding: theme.spacing(1),
        boxShadow: '0px 3px 3px rgba(0, 0, 0, .3)',
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
      },
    },
    city: {
      textTransform: 'capitalize',
      textStyle: 'italic',
    },
  };

  useEffect(() => {
    (async () => {
      const cityStories = await getCityStories(city);
      setStoryList(cityStories);
    })();
  }, []);

  return (
    <Box component={'div'} sx={styles.root}>
      <AppBar position="sticky" elevation={0}>
        <Typography variant="h5" display="inline">
          <Box component={'span'} sx={styles.city}>{city}</Box> News
        </Typography>
      </AppBar>
      <Grid container spacing={3} sx={styles.root}>
        {storyList.map((story) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={story.id}>
            <Story story={story} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Section;

import React from 'react';
import {
  Grid,
  Fab,
  Box,
  SxProps,
} from '@mui/material';
import { Publish } from '@mui/icons-material';
import Section from '../components/Section';
import { FeedList } from '../types/FeedList';
import feedList from '../data/feedList';
import { useTheme, Theme } from '@mui/material/styles';

const Homepage: React.FC = () => {
  const theme: Theme = useTheme();
  const fabStyle: SxProps = {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {feedList.map((feedItem: FeedList) => (
            <Section key={feedItem.city} city={feedItem.city} />
          ))}
        </Grid>
      </Grid>
      <Box zIndex="tooltip" sx={fabStyle}>
        <Fab onClick={scrollToTop} size="large">
          <Publish />
        </Fab>
      </Box>
    </>
  );
};

export default Homepage;

import React from 'react';
import Homepage from './Homepage';
import Header from '../components/Header';
import { Container } from '@mui/material';
import Footer from '../components/Footer';
import { useTheme, Theme } from '@mui/material/styles';

const App: React.FC = () => {
  const theme: Theme = useTheme();
  return (
    <Container maxWidth={false} sx={{ backgroundColor: theme.palette.background.default }}>
      <Header />
      <Homepage />
      <Footer />
    </Container>
  );
};

export default App;

import React from 'react';
import {
  Card,
  CardActionArea,
  Typography,
  CardMedia,
  CardContent,
  SxProps,
} from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  StoryProps,
} from '../types';
import { useTheme } from '@mui/material/styles';

dayjs.extend(relativeTime);

const Story: React.FC<StoryProps> = ({ story }) => {
  const theme = useTheme();
  const styles: Record<string, SxProps> = {
    root: {
      padding: theme.spacing(1),
      height: '100%',
      width: '100%',

      '& .MuiCardActionArea-root': {
        height: '100%',
      },

      '& .MuiTypography-caption': {
        position: 'absolute',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: 'white',
        backgroundColor: 'rgba(0, 0, 0, .5)',
      },
    },
    media: {
      width: '100%',
      height: '176px',
      overflow: 'hidden',
    },
  };
  const imageUrlParams = '&w=0&h=200&c=14';

  return (
    <Card sx={styles.root} elevation={1}>
      <CardActionArea href={story.link} target="_blank">
        <Typography variant="caption" display="block">
          {dayjs().to(dayjs(story.date))}
        </Typography>
        <CardMedia image={`${story.image}${imageUrlParams}`} component="img" sx={styles.media} />
        <CardContent>
          <Typography variant="body1" display="block" align="center">
            {story.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Story;

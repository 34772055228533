import React from 'react';
import {
  Grid,
  Typography,
  Link,
  SxProps,
  Theme,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';

const Footer: React.FC = () => {
  const theme: Theme = useTheme();
  const styles: Record<string, SxProps> = {
    root: {
      borderTop: '1px solid #ccc',
      paddingTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
  };

  return (
    <Grid container sx={styles.root}>
      <Grid item xs={12}>
        <Link href="mailto:editor@thefresnonews.com">editor@thefresnonews.com</Link>
        <Typography variant="caption" display="block">
          &copy; Copyright {dayjs().year()} The Fresno News. All Rights Reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;

import axios, { AxiosResponse } from 'axios';
import { StoryModel } from '../types';
import { Cities } from '../types';

const getCityStories = async (city: Cities): Promise<StoryModel[]> => {
  const url = `https://tfn-storage211906-dev.s3.us-west-2.amazonaws.com/public/${city}.json`;
  const { data }: AxiosResponse = await axios.get(url);

  return data;
};

export default getCityStories;
